// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import ahoy from "ahoy.js";
ahoy.trackAll();

window.addEventListener("click", function(event) {
  let link = event.target;
  while (!(link instanceof HTMLAnchorElement)) {
    link = link.parentNode;
    if (!link) return;
  }
  if (link.tagName !== "A") return;

  if (!link.isContentEditable && link.host !== window.location.host)
    link.setAttribute("target", "_blank");
}, true);
// window.addEventListener("turbolinks:load", () => {
//   Init(
//     document.body.attributes.controller_name.value,
//     document.body.attributes.action_name.value
//   )
// });


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
